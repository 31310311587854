import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gql, useQuery } from '@apollo/client';
import { NumberDashboard, SeriesDashboard } from './components/Metrics';
import ButtonAppBar from './components/ButtonAppBar';

function Home() {
  const [homeMetrics, setHomeMetrics] = useState();
  const [numberMetrics, setNumberMetrics] = useState();
  const [seriesMetrics, setSeriesMetrics] = useState();

  const metricCharts = new Map([['cumulativeUsers', '100%']]);
  const tableNames = [
    'usersByWeek',
    'newUsersAndNewAtRiskUsersByQuarter',
    'episodesByWeek',
    'painByProvider',
    'psfsByProvider',
    'npsConversionRate',
    'npsByProvider',
    'satisfactionAndCompassionRates',
    'adherenceRates',
    'adherenceByMonth',
  ];
  const metricTables = new Map();
  tableNames.forEach(name => {
    // full width tables
    if (name === 'newUsersAndNewAtRiskUsersByQuarter') {
      metricTables.set(name, '100%');
    } else {
      metricTables.set(name, '50%');
    }
  });

  const HOME_METRICS = gql`
    query getMetrics {
      getMetrics {
        groupList {
          id
          name
        }
        totalUserAccounts {
          name
          value
        }
        distinctUsersWithVisit {
          name
          value
        }
        distinctEpisodesWithVisit {
          name
          value
        }
        numRepeatUsers {
          name
          value
        }
        npsScore {
          name
          value
        }
        noShowRate {
          name
          value
        }
        enrollmentRate {
          name
          value
        }
        enrollmentRateCompletedBeforeVisit {
          name
          value
        }
        cumulativeUsers {
          name
          data {
            values
          }
          headings
        }
        newUsersAndNewAtRiskUsersByQuarter {
          name
          data {
            values
          }
          headings
        }
        usersByWeek {
          name
          data {
            values
          }
          headings
        }
        episodesByWeek {
          name
          data {
            values
          }
          headings
        }
        painByProvider {
          name
          data {
            values
          }
          headings
        }
        psfsByProvider {
          name
          data {
            values
          }
          headings
        }
        adherenceByMonth {
          name
          data {
            values
          }
          headings
        }
        adherenceRates {
          name
          data {
            values
          }
          headings
        }
        npsConversionRate {
          name
          data {
            values
          }
          headings
        }
        npsByProvider {
          name
          data {
            values
          }
          headings
        }
        satisfactionAndCompassionRates {
          name
          data {
            values
          }
          headings
        }
      }
    }
  `;
  const { data, error } = useQuery(HOME_METRICS);
  useEffect(() => {
    if (data) {
      setHomeMetrics(data.getMetrics);
      // set number data
      let numberData = {};
      Object.entries(data.getMetrics).forEach(([key, metric]) => {
        if (typeof metric.value === 'number' || typeof metric.value === 'string') {
          numberData[key] = metric;
        }
      });
      setNumberMetrics(numberData);
      // filter series data
      let seriesData = {};
      Object.entries(data.getMetrics).forEach(([key, metrics]) => {
        if (metricCharts.has(key)) {
          let chartData = { xAxis: [], yAxis: [], name: metrics.name };
          metrics.data.forEach(metric => {
            if (metric.values.length >= 2) {
              chartData.xAxis.push(metric.values[0]);
              chartData.yAxis.push(Number(metric.values[1]));
            }
          });
          seriesData[key] = chartData;
        }
        if (metricTables.has(key)) {
          seriesData[key] = metrics;
        }
      });
      setSeriesMetrics(seriesData);
    }
  }, [data]);
  if (error) {
    console.log('error fetching gql data: ' + error);
  }

  const FlexContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    gap: theme.spacing(2),
  }));
  const FullWidthBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }));

  return (
    <Box>
      <ButtonAppBar />
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 4,
        }}
      >
        <FlexContainer>
          <FullWidthBox>
            <Box display="flex" textAlign="left" my={2}>
              <Typography variant="h2" component="h2">
                Aware Health Metrics Dashboard
              </Typography>
            </Box>
            <Grid container spacing={1} columns={{ xl: 4, lg: 3 }}>
              {homeMetrics &&
                homeMetrics?.groupList?.map(group => {
                  return (
                    <Grid item xs={1} key={group.id}>
                      <Typography variant="body1">
                        <Link to={`/report/${group.id}`}>{group.name}</Link>
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </FullWidthBox>
          <NumberDashboard data={numberMetrics} />
          {seriesMetrics && <SeriesDashboard seriesData={seriesMetrics} metricCharts={metricCharts} metricTables={metricTables} />}
        </FlexContainer>
      </Box>
    </Box>
  );
}

export default Home;
